import ui from "./UserInfo";

class PermCtrl {
    /**
     * 判断当前用户角色是否有指定角色
     * @type {(permission: string) => boolean}
     * @property {string} permission 指定角色
     */
    public static isAuthorized(permission: string): boolean {
        const permissions = ui.getPermissions();
        if (permissions === null) {
            return false;
        }

        return permissions.some(r => r === permission);
    }

    /**
     * 判断当前用户角色是否有指定角色组中的一个
     * @type {(permissionNames: string[]) => boolean}
     * @property {string[]} permissionNames 指定角色组
     */
    public static isAnyAuthorized(permissionNames: string[]): boolean {
        const permissions = ui.getPermissions();
        let authorized = false;
        permissions.forEach(rn => {
            if (permissionNames.some(r => r === rn)) {
                authorized = true;
            }
        });
        return authorized;
    }

    /**
     * 判断当前用户角色是否有指定角色组中的所有角色
     * @type {(permissionNames: string[]) => boolean}
     * @property {string[]} permissionNames 指定角色组
     */
    public static areAllAuthorized(permissionNames: string[]): boolean {
        const permissions = ui.getPermissions();
        let authorized = true;
        permissionNames.forEach(rn => {
            if (!permissions.some(r => r === rn)) {
                authorized = false;
            }
        });
        return authorized;
    }
}

export default PermCtrl;
