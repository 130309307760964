import { Descriptions } from "antd";
import * as React from "react";
import { IAppliedProcessDTO } from "../../app/WebAPIClients";
import Util from "../Util";

declare interface IAppliedProcessInfoListProps {
    appliedProcesses: IAppliedProcessDTO[];
    showHeader?: boolean;
    label?: string;
    extraInfo?: JSX.Element;
}

class AppliedProcessInfoList extends React.Component<
    IAppliedProcessInfoListProps,
    {}
> {
    public static defaultProps: IAppliedProcessInfoListProps;
    public render() {
        const t = this;
        const { appliedProcesses, showHeader, label, extraInfo } = t.props;

        return (
            <Descriptions
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                title={
                    showHeader
                        ? Util.isNotNullAndNotEmpty(label)
                            ? label
                            : "工艺列表"
                        : null
                }
            >
                {Util.isNotNullAndNotEmptyArray(appliedProcesses) ? (
                    appliedProcesses.map(p => {
                        return (
                            <Descriptions.Item label={p.name} key={p.id}>
                                {p.quantity + " " + p.unit}
                            </Descriptions.Item>
                        );
                    })
                ) : (
                    <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                        没有数据
                    </div>
                )}
                {extraInfo}
            </Descriptions>
        );
    }
}

AppliedProcessInfoList.defaultProps = {
    appliedProcesses: [],
    showHeader: false,
    label: "",
    extraInfo: null
};

export default AppliedProcessInfoList;
