import settings from "./Settings";
import userInfo from "./UserInfo";

class ExportUtil {
    // 除PageCompareInventoryExcelFile(由于对比盘点文件界面是post方式)，界面外的所有导出URL都调用此函数
    public setExportUrl(exportAPI: string) {
        const webApiUrl: string = settings.webApiUrl;
        const jwtToken = userInfo.getAccessToken();
        window.location.href = `${webApiUrl + exportAPI}${this.addParameter(
            exportAPI,
            "access_token",
            jwtToken
        )}`;
    }

    public addParameter(parameters: string, para: string, value: any): string {
        return parameters.includes("&") || !parameters.endsWith("?")
            ? "&" + para + "=" + value
            : para + "=" + value;
    }
}

export default new ExportUtil();
