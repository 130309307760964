import React from "react";
import dtClientSVC from "./DingtalkClientSVC";
import settings from "./Settings";
import userInfo from "./UserInfo";

import { Redirect, Route } from "react-router-dom";

export default ({ component: Component, ...rest }) => {
    if (!userInfo.loggedIn()) {
        const isDDAvailable = dtClientSVC.isJSAPIAvailable();
        const loginUrl =
            settings.environment === "development" && !isDDAvailable
                ? "/devlogin"
                : !isDDAvailable
                    ? "/loginbypassword"
                    : "/login";
        return (
            <Redirect
                to={{
                    pathname: loginUrl,
                    state: {
                        originalUrl: window.location.href,
                        nextPathname: rest.location.pathname
                    }
                }}
            />
        );
    }

    return <Route component={Component} {...rest} />;
};
