import { message } from "antd";

class ErrorHandler {
    private static handler: ErrorHandler = null;

    constructor() {
        if (ErrorHandler.handler === null) {
            ErrorHandler.handler = this;
        }

        return ErrorHandler.handler;
    }

    public handleErr(
        msg: string,
        err?: { message: string; code: number },
        duration: number = 10
    ) {
        let errDetail = "";
        let errMsg = "";
        if (err) {
            errDetail = JSON.stringify(err);
        }
        errMsg = msg + errDetail;
        message.error(errMsg, duration);
    }
}

export default new ErrorHandler();
