﻿import {
    IAppliedPriceItemDTO,
    IAppliedProcessDTO,
    IPaymentOrderForListDTO,
    ISalesOrderDetailDTO,
    ISalesOrderForListDTO,
    IShippingOrderForListDTO,
    ITilePackingCaseForListDTO,
    ITransferDetailDTO,
    ITransferOrderForListDTO
} from "../app/WebAPIClients";

export declare interface ITransferOrderListWithDetails
    extends ITransferOrderForListDTO {
    transferDetails?: ITransferDetailDTO[];
}

export declare interface IPaymentOrderListWithDetails
    extends IPaymentOrderForListDTO {
    items?: ISalesOrderDetailDTO[];
    appliedPrices?: IAppliedPriceItemDTO[];
}

export declare interface IBackendSysInfo {
    buildVersion: string;
    releaseTime: string;
    protocolName: string;
    clientIPAddress: string;
}

export declare interface ISalesOrderForListForUI extends ISalesOrderForListDTO {
    shippingOrders?: IShippingOrderForListDTO[];
    tilePackingCases?: ITilePackingCaseForListDTO[];
}

export enum BlockDimensionType {
    /**
     * 收方尺寸
     */
    QuarryReported = "收方",

    /**
     * 入库尺寸
     */
    Validated = "入库",

    /**
     * 修边尺寸
     */
    Trimmed = "修边"
}

/**
 * 荒料尺寸
 */
export declare interface IBlockSize {
    /**
     * 长度
     */
    length: number;

    /**
     * 宽度
     */
    width: number;

    /**
     * 高度
     */
    height: number;
}

export declare interface IAppliedProcessGroupByKey
    extends Pick<
        IAppliedProcessDTO,
        "startTime" | "endTime" | "machineryId" | "operatorName"
    > {}

export declare interface IAppliedProcessGroupByItem
    extends IAppliedProcessGroupByKey {
    appliedProcesses: IAppliedProcessDTO[];
}
