import nattyStorage from "natty-storage";
import { IUserInfoDTO } from "../app/WebAPIClients";
import Util from "./Util";

class UserInfo {
    public static getUserInfo(): IUserInfoDTO {
        const cache = this.getStorage();
        return cache && cache.get(this.cacheKey);
    }
    public static getPermissions(): string[] {
        const ui = this.getUserInfo();
        if (!ui || !ui.permissions) {
            return [];
        }
        return ui.permissions!;
    }

    public static getName(): string {
        const cache = this.getStorage();
        const ui = cache.get(this.cacheKey);
        if (!ui) {
            return "";
        }
        return ui.name;
    }

    public static loggedIn(): boolean {
        const cache = this.getStorage();
        const result = cache.has(this.cacheKey);
        return result.has;
    }

    public static setUserInfo(userInfo: IUserInfoDTO): void {
        if (!userInfo) {
            return;
        }
        const cache = this.getStorage();
        cache.set(this.cacheKey, userInfo);
    }

    public static getDDConfig(): any {
        const cache = this.getStorage();
        const ddConfig = cache.get(this.ddConfigCacheKey);
        return ddConfig;
    }

    public static setDDConfig(ddConfig): void {
        if (!ddConfig) {
            return;
        }
        const cache = this.getStorage();
        cache.set(this.ddConfigCacheKey, ddConfig);
    }

    public static getAccessToken(): string | null {
        const cache = this.getStorage();
        return cache.get(this.jwtTokenCacheKey);
    }

    public static setAccessToken(jwtToken: string): void {
        if (!jwtToken) {
            return;
        }
        const cache = this.getStorage();
        cache.set(this.jwtTokenCacheKey, jwtToken);
    }

    private static storage: any = null;
    private static storageKey: string = "userInfoStorage";
    private static cacheKey: string = "userInfo";
    private static ddConfigCacheKey: string = "ddConfig";
    private static jwtTokenCacheKey: string = "jwtToken_Cache";
    private static cacheDurationInMin: number = 10;

    private static getStorage(): any {
        if (this.storage === null) {
            this.storage = nattyStorage(
                Util.getSessionCacheOption(
                    this.storageKey,
                    this.cacheDurationInMin
                )
            );
        }
        return this.storage;
    }
}
export default UserInfo;
