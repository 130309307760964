import { message } from "antd";

class Validator {
    public static initializeShowError(showErr: boolean): boolean {
        return showErr === null || showErr === true;
    }
    public static showAlert(msg: string): void {
        message.error(msg);
    }
    public static isEmptyOrNull(value: any): boolean {
        return (
            typeof value === "undefined" ||
            value === null ||
            value === "" ||
            value.length <= 0
        );
    }
    public static isNumber(value: string): boolean {
        return !isNaN(parseFloat(value));
    }
    public static isDate(value: any): boolean {
        if (typeof value === "undefined" || value === null) {
            return false;
        }
        return typeof value.getMonth === "function";
    }

    // 验证传入的值是否是数字
    // value [object]: 要验证的值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    // 如果传入值为空，则返回true，所以如果需要检查项目必填，须在调用此函数之前单独调用require函数
    public static numeric(
        value: any,
        fieldName: string,
        showErr: boolean = true
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const isEmpty = this.isEmptyOrNull(value);
        if (isEmpty) {
            return true;
        }
        const valid = this.isNumber(value);
        if (!valid && showErr) {
            this.showAlert(fieldName + "必须是数字");
        }
        return valid;
    }

    // 验证传入的值是否为数字以及是否在指定范围之内
    // value [string]: 要验证的值
    // min [int]: 最小值，如果传null则不验证最小值
    // max [int]: 最大值，如果传null则不验证最大值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    // 如果传入值为空，则返回true，所以如果需要检查项目必填，须在调用此函数之前单独调用require函数
    public static range(
        value: any,
        min: number,
        max: number,
        fieldName: string,
        showErr: boolean = true
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const isEmpty = this.isEmptyOrNull(value);
        if (isEmpty) {
            return true;
        }
        const valid = this.numeric(value, fieldName, showErr);
        if (!valid) {
            return valid;
        }
        const intVal = parseInt(value);
        if (typeof min !== "undefined" && min !== null) {
            if (intVal < min) {
                if (showErr) {
                    this.showAlert(fieldName + "必须大于或等于" + min);
                }
                return false;
            }
        }
        if (typeof max !== "undefined" && max !== null) {
            if (intVal > max) {
                if (showErr) {
                    this.showAlert(fieldName + "必须小于或等于" + max);
                }
                return false;
            }
        }
        return true;
    }

    // 验证是否为空
    // value [object]: 要验证的值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    public static require(
        value: any,
        fieldName: string,
        showErr: boolean = true
    ): boolean {
        showErr = this.initializeShowError(showErr);

        if (typeof value === "string") {
            value = value.trim();
        }
        const isEmpty = this.isEmptyOrNull(value);
        if (isEmpty && showErr) {
            this.showAlert("请输入" + fieldName);
        }
        return !isEmpty;
    }
}

export default Validator;
