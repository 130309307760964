import Perm from "../app/Perm";
import { ITransferDetailDTO, StoneArtifactType } from "../app/WebAPIClients";
import Consts from "./Consts";
import PermCtrl from "./PermCtrl";
import Settings from "./SettingInfo";
import SettingsDefinition from "./SettingsDefinition";
import StockingAreaUtil from "./StockingAreaUtil";
import Util from "./Util";
const {
    ApprovalStatus,
    BlockStatus,
    SlabType,
    SlabBundleStatus,
    TilePackingCaseStatusOptions,
    StoneArtifactTypeOptions
} = Consts;

class TransferOrderUtil {
    public getBlockColumns(
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ) {
        const blockColumns: any = [
            {
                title: "荒料编号",
                key: "blockNumber",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.block.blockNumber;
                }
            },
            {
                title: "品名",
                key: "categoryName",
                align: "center",
                render: item => {
                    return Util.getItemName(
                        item.block.categoryId,
                        categoryList
                    );
                }
            },
            {
                title: "长 x 高 x 宽(mm)",
                key: "size",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    const block = item.block;
                    return (
                        block.validatedLength +
                        " x " +
                        block.validatedHeight +
                        " x " +
                        block.validatedWidth
                    );
                }
            },
            {
                title: "等级",
                key: "grade",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    const block = item.block;
                    return Util.getItemName(block.gradeId, gradeList);
                }
            },
            {
                title: "状态",
                key: "status",
                render: (item: ITransferDetailDTO) => {
                    const block = item.block;
                    return BlockStatus.getStatus(block.status).text;
                }
            },
            {
                title: "调拨费用",
                key: "transportFee",
                dataIndex: "transportFee",
                align: "center"
            }
        ];

        // 通过判断EnableBlockNumberFromQuarryKey，决定是否显示矿山荒料号，则将其添加到Columns数组中
        const blockNumberFromQuarryColumn = {
            title: "矿山荒料号",
            key: "blockNumberFromQuarry",
            align: "center",
            render: (item: ITransferDetailDTO) => {
                return item.block.blockNumberFromQuarry;
            }
        };
        if (
            Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            )
        ) {
            blockColumns.splice(1, 0, blockNumberFromQuarryColumn);
        }

        return blockColumns;
    }

    public getBundleColumns(
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ) {
        const bundleColumns: any = [
            {
                title: "荒料编号",
                key: "prefix",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.bundle.prefix;
                }
            },
            {
                title: "扎号",
                key: "bundleNo",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return (
                        item.bundle.totalBundleCount +
                        "-" +
                        item.bundle.bundleNo
                    );
                }
            },
            {
                title: "品名",
                key: "categoryName",
                align: "center",
                render: item => {
                    return Util.getItemName(
                        item.bundle.categoryId,
                        categoryList
                    );
                }
            },
            {
                title: "厚度",
                key: "thickness",
                align: "center",
                render: item => {
                    return item.bundle.thickness;
                }
            },
            {
                title: "长 x 宽(mm)",
                key: "size",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    const bundle = item.bundle;
                    return (
                        bundle.lengthAfterStockIn +
                        " x " +
                        bundle.widthAfterStockIn
                    );
                }
            },
            {
                title: "总片数",
                key: "totalSlabCount",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.bundle.totalSlabCount;
                }
            },
            {
                title: "方量(m²)",
                key: "Area",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.bundle.area;
                }
            },
            {
                title: "状态",
                key: "status",
                render: (item: ITransferDetailDTO) => {
                    const bundle = item.bundle;
                    return SlabBundleStatus.getStatus(bundle.status).text;
                }
            },
            {
                title: "类型",
                key: "type",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return SlabType.getType(item.bundle.type).text;
                }
            },
            {
                title: "调拨费用",
                key: "transportFee",
                dataIndex: "transportFee",
                align: "center"
            }
        ];

        // 通过判断EnableBlockNumberFromQuarryKey，决定是否显示矿山荒料号，则将其添加到Columns数组中
        const blockNumberFromQuarryColumn = {
            title: "矿山荒料号",
            key: "blockNumberFromQuarry",
            align: "center",
            render: (item: ITransferDetailDTO) => {
                return item.bundle.blockNumberFromQuarry;
            }
        };

        const enableBlockNumberFromQuarry = Settings.getBoolValue(
            SettingsDefinition.EnableBlockNumberFromQuarryKey
        );
        if (enableBlockNumberFromQuarry) {
            bundleColumns.splice(1, 0, blockNumberFromQuarryColumn);
        }

        // 如果没有查看大扎等级的权限，则不显示该列，否则将该列添加到Columns数组中
        const gradeColumn = {
            title: "等级",
            key: "grade",
            align: "center",
            render: (item: ITransferDetailDTO) => {
                const bundle = item.bundle;
                return Util.getItemName(bundle.gradeId, gradeList);
            }
        };

        if (PermCtrl.isAuthorized(Perm.SB_G_R)) {
            var gradeColumnIdx = enableBlockNumberFromQuarry ? 6 : 5;
            bundleColumns.splice(gradeColumnIdx, 0, gradeColumn);
        }

        return bundleColumns;
    }

    public getSlabColumns(
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ) {
        const slabColumns: any = [
            {
                title: "荒料编号",
                key: "bundlePrefix",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.slab.bundlePrefix;
                }
            },
            {
                title: "大板序号",
                key: "sequenceNumber",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.slab.sequenceNumber;
                }
            },
            {
                title: "品名",
                key: "categoryName",
                align: "center",
                render: item => {
                    return Util.getItemName(item.slab.categoryId, categoryList);
                }
            },
            {
                title: "厚度",
                key: "thickness",
                align: "center",
                render: item => {
                    return item.slab.thickness;
                }
            },
            {
                title: "长 x 宽(mm)",
                key: "size",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return Util.getSlabSpec(item.slab);
                }
            },
            {
                title: "面积(m²)",
                key: "slabArea",
                align: "center",
                render: item => {
                    return Util.calculateAreaForSlab(item.slab);
                }
            },
            {
                title: "等级",
                key: "grade",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    const slab = item.slab;
                    return Util.getItemName(slab.gradeId, gradeList);
                }
            },
            {
                title: "状态",
                key: "status",
                render: (item: ITransferDetailDTO) => {
                    const slab = item.slab;
                    return SlabBundleStatus.getStatus(slab.status).text;
                }
            },
            {
                title: "类型",
                key: "type",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return SlabType.getType(item.slab.type).text;
                }
            },
            {
                title: "调拨费用",
                key: "transportFee",
                dataIndex: "transportFee",
                align: "center"
            }
        ];

        const blockNumberFromQuarryColumn = {
            title: "矿山荒料号",
            key: "blockNumberFromQuarry",
            align: "center",
            render: (item: ITransferDetailDTO) => {
                return item.slab.blockNumberFromQuarry;
            }
        };
        if (
            Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            )
        ) {
            slabColumns.splice(1, 0, blockNumberFromQuarryColumn);
        }

        return slabColumns;
    }

    public getTPCColumns() {
        const tpcColumns: any = [
            {
                title: "工程名称",
                key: "proName",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.tilePackingCase.projectName;
                }
            },
            {
                title: "包装箱号",
                key: "caseNumber",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.tilePackingCase.caseNumber;
                }
            },
            {
                title: "总片数",
                key: "totalTileCount",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.tilePackingCase.totalTileCount;
                }
            },
            {
                title: "总面积",
                key: "totalArea",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    return item.tilePackingCase.totalArea;
                }
            },
            {
                title: "状态",
                key: "status",
                align: "center",
                render: (item: ITransferDetailDTO) => {
                    const tilePackingCase = item.tilePackingCase;
                    return TilePackingCaseStatusOptions.getStatus(
                        tilePackingCase.status
                    ).text;
                }
            },
            {
                title: "调拨费用",
                key: "transportFee",
                dataIndex: "transportFee",
                align: "center"
            }
        ];

        return tpcColumns;
    }

    public getColumnsDataByType(
        type: StoneArtifactType,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ) {
        let columnsData: any[] = [];

        switch (type) {
            case StoneArtifactType.Block:
                columnsData = this.getBlockColumns(categoryList, gradeList);
                break;
            case StoneArtifactType.Slab:
                columnsData = this.getSlabColumns(categoryList, gradeList);
                break;
            case StoneArtifactType.StoneBundle:
                columnsData = this.getBundleColumns(categoryList, gradeList);
                break;
            case StoneArtifactType.Tile:
                columnsData = this.getTPCColumns();
                break;
        }

        return columnsData;
    }

    public getTabColumns(warehouseOptions) {
        const columns: any = [
            {
                title: "调拨单号",
                dataIndex: "orderNumber",
                key: "orderNumber",
                width: "10%",
                align: "center"
            },
            {
                title: "调拨类型",
                dataIndex: "type",
                key: "type",
                align: "center",
                render: type => {
                    return StoneArtifactTypeOptions.getType(type).text;
                }
            },
            {
                title: "调出仓库",
                dataIndex: "fromWarehouseId",
                key: "fromWarehouseId",
                align: "center",
                render: fromWarehouseId =>
                    StockingAreaUtil.getWarehouseForUI(
                        fromWarehouseId,
                        warehouseOptions
                    )
            },
            {
                title: "调入仓库",
                dataIndex: "toWarehouseId",
                key: "toWarehouseId",
                align: "center",
                render: toWarehouseId =>
                    StockingAreaUtil.getWarehouseForUI(
                        toWarehouseId,
                        warehouseOptions
                    )
            },
            {
                title: "调拨日期",
                dataIndex: "transferDate",
                key: "transferDate",
                align: "center",
                render: transferDate => {
                    return Util.formatDate(transferDate);
                }
            },
            {
                title: "数量",
                dataIndex: "itemsCount",
                key: "itemsCount",
                align: "center"
            },
            {
                title: "调拨费用(元)",
                dataIndex: "totalTransportFee",
                key: "totalTransportFee",
                align: "center"
            },
            {
                title: "调拨原因",
                dataIndex: "transportReason",
                key: "transportReason",
                align: "center"
            },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                align: "center",
                render: status => {
                    return ApprovalStatus.getStatus(status).text;
                }
            },
            {
                title: "备注",
                dataIndex: "notes",
                key: "notes",
                align: "center"
            }
        ];

        return columns;
    }
}

export default new TransferOrderUtil();
