import { Descriptions } from "antd";
import React from "react";
import {
    BlockWithBundlesSlabsDTO,
    IBlockDTO,
    IBlockWithBundlesSlabsDTO
} from "../app/WebAPIClients";
import Settings from "./SettingInfo";
import SettingsDefinition from "./SettingsDefinition";
import { BlockDimensionType, IBlockSize } from "./type";
import Util from "./Util";

class BlockUtil {
    /**
     * 获取根据设置的宽和高顺序整理好的荒料尺寸的标题
     */
    public getBlockDimensionTitle(): string {
        const order = this.getDimensionDisplayOrder();
        return order === SettingsDefinition.BlockDimensionLengthHeightWidth
            ? "长高宽"
            : "长宽高";
    }

    /**
     * 获取根据设置的宽和高顺序整理好的荒料尺寸的文字
     * @param length 长
     * @param width 宽
     * @param height 高
     */
    public getBlockDimension(
        length: number,
        width: number,
        height: number
    ): string {
        const order = this.getDimensionDisplayOrder();

        const widthHeightText =
            order === SettingsDefinition.BlockDimensionLengthHeightWidth
                ? height + " x " + width
                : width + " x " + height;
        return length + " x " + widthHeightText;
    }

    /**
     * 获取根据设置的宽和高顺序整理好的指定类型的荒料尺寸的文字
     * @param block 荒料对象
     * @param type 荒料尺寸类型
     */
    public getBlockDimensionByType(
        block: IBlockDTO | IBlockWithBundlesSlabsDTO,
        type: BlockDimensionType
    ): string {
        const { length, width, height } = this.getBlockSize(block, type);
        return this.getBlockDimension(length, width, height);
    }

    public getBlockSize(
        block: IBlockDTO | IBlockWithBundlesSlabsDTO,
        type: BlockDimensionType
    ): IBlockSize {
        let length: number;
        let width: number;
        let height: number;
        switch (type) {
            case BlockDimensionType.QuarryReported:
                length = block.quarryReportedLength;
                width = block.quarryReportedWidth;
                height = block.quarryReportedHeight;
                break;
            case BlockDimensionType.Validated:
                length = block.validatedLength;
                width = block.validatedWidth;
                height = block.validatedHeight;
                break;
            case BlockDimensionType.Trimmed:
                length = block.trimmedLength;
                width = block.trimmedWidth;
                height = block.trimmedHeight;
                break;
        }

        return { length, width, height };
    }

    public ShowBlockDimensionField(
        block: BlockWithBundlesSlabsDTO,
        dimensionType: BlockDimensionType,
        labelPrefix: string
    ) {
        const t = this;

        if (!block) {
            return null;
        }

        const prefix: string = Util.isNotNullAndNotEmpty(labelPrefix)
            ? labelPrefix
            : dimensionType.toString();
        const title = prefix + this.getBlockDimensionTitle();
        const dimensionText = this.getBlockDimensionByType(
            block,
            dimensionType
        );

        return (
            <Descriptions.Item label={title}>{dimensionText}</Descriptions.Item>
        );
    }

    private getDimensionDisplayOrder(): BlockDimensionDisplayOrder {
        return Settings.getValue(
            SettingsDefinition.BlockDimensionDisplayOrderKey
        ) as BlockDimensionDisplayOrder;
    }
}

export default new BlockUtil();
