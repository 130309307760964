import * as React from "react";
import {
    IMachineryDTO,
    IWorkOrderForListDTO,
    IWorkOrderFullInfoDTO
} from "../app/WebAPIClients";
import Consts from "./Consts";
import Util from "./Util";

const { WorkOrderType } = Consts;
class WorkOrderUtil {
    public getWorkOrderType(
        wo: IWorkOrderForListDTO | IWorkOrderFullInfoDTO
    ): string {
        let type: string = null;
        if (!wo) {
            return type;
        }

        if (
            wo.manufacturingState === 90 &&
            wo.endState &&
            wo.orderType !== 10
        ) {
            type = wo.endState >= 60 ? "光板" : "毛板";
        } else {
            const woType = WorkOrderType.getOrderType(wo.orderType);
            if (woType) {
                type = woType.text;
            }
        }

        return type;
    }

    public getMachineryName(
        machineryId: number,
        factoryList: IIdNameItem[],
        machineries: IMachineryDTO[]
    ): string {
        if (
            !(
                machineryId &&
                factoryList &&
                machineries &&
                factoryList.length > 0 &&
                machineries.length > 0
            )
        ) {
            return "";
        }

        let name = "";
        const item = machineries.find(i => i.id === machineryId);
        if (item) {
            name = item.typeName + "-" + item.name;
        }

        if (factoryList.length === 1) {
            return name;
        }

        const factoryId: number = machineries.find(m => m.id === machineryId)
            .factoryId;

        if (factoryId) {
            const factoryName = Util.getItemName(factoryId, factoryList);
            name = factoryName + "-" + name;
        }

        return name;
    }
}

export default new WorkOrderUtil();
