import { observer } from "mobx-react";
import React from "react";

import { Form, FormInstance, Modal } from "antd";
const FormItem = Form.Item;

import version from "../../app/releaseInfo.json";
import Store from "./store";

declare interface ISysInfoDialogProps {
    onClose: () => void;
    visible: boolean;
}
@observer
class SysInfoDialog extends React.Component<ISysInfoDialogProps, null> {
    public static defaultProps: ISysInfoDialogProps;
    private formRef: React.RefObject<FormInstance>;
    constructor(props: ISysInfoDialogProps) {
        super(props);
        this.formRef = React.createRef();
    }

    public async componentDidUpdate(prevProps: ISysInfoDialogProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            await this.loadData();
        }
    }

    public render() {
        const t = this;

        const { visible } = t.props;

        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const h3TitleSty = { marginBottom: 0, marginLeft: 14, marginTop: 10 };
        const className = "sysInfoTitle";

        return (
            <Modal
                title="系统信息"
                visible={visible}
                onCancel={this.handleCloseChange}
                footer={null}
                centered={true}
            >
                <Form name="SysInfoDialog" ref={this.formRef}>
                    <h3 style={h3TitleSty} className={className}>
                        <b>客户端</b>
                    </h3>
                    <FormItem
                        {...formItemLayout}
                        label="版本号"
                        style={{ marginBottom: "0px" }}
                    >
                        <span>{version.buildVersion}</span>
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="发布时间"
                        style={{ marginBottom: "10px" }}
                    >
                        <span>{version.releaseTime}</span>
                    </FormItem>
                    <h3 style={h3TitleSty} className={className}>
                        <b>服务端</b>
                    </h3>
                    <FormItem
                        {...formItemLayout}
                        label="版本号"
                        style={{ marginBottom: "0px" }}
                    >
                        <span>
                            {Store.backendSysInfo
                                ? Store.backendSysInfo.buildVersion
                                : "未知"}
                        </span>
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="发布时间"
                        style={{ marginBottom: "10px" }}
                    >
                        <span>
                            {Store.backendSysInfo
                                ? Store.backendSysInfo.releaseTime
                                : "未知"}
                        </span>
                    </FormItem>
                    <h3 style={h3TitleSty} className={className}>
                        <b>网络</b>
                    </h3>
                    <FormItem
                        {...formItemLayout}
                        label="协议"
                        style={{ marginBottom: "0px" }}
                    >
                        <span>
                            {Store.backendSysInfo
                                ? Store.backendSysInfo.protocolName
                                : "未知"}
                        </span>
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="本地IP"
                        style={{ marginBottom: "10px" }}
                    >
                        {Store.backendSysInfo
                            ? Store.backendSysInfo.clientIPAddress
                            : "未知"}
                    </FormItem>
                </Form>
            </Modal>
        );
    }

    private handleCloseChange = () => {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    };

    private loadData = async () => {
        await Store.getSystemInfo();
    };
}

SysInfoDialog.defaultProps = {
    onClose: () => {},
    visible: false
};

export default SysInfoDialog;
