import nattyStorage from "natty-storage";
import { SortInfo } from "../app/WebAPIClients";
import tenantInfo from "./TenantInfo";
import Util from "./Util";

class ColumnSettings {
    // 从WebStorage中获取显示列的信息，若无对应信息则返回null
    public static getVisibleColumns(componentName: string): string[] {
        const columnSettings = this.getColumnSettings(componentName);
        if (columnSettings && columnSettings.VisibleColumns) {
            return columnSettings.VisibleColumns;
        }

        return null;
    }

    // 从WebStorage中获取排序的信息，若无排序信息则返回null
    public static getSortSettings(componentName: string): SortInfo[] {
        const columnSettings = this.getColumnSettings(componentName);
        if (columnSettings && columnSettings.SortSettings) {
            return columnSettings.SortSettings as SortInfo[];
        }

        return null;
    }

    // 从WebStorage中获取显示列的信息和排序的信息，若无对应信息则返回null
    public static getColumnSettings(componentName: string): IColumnSettings {
        const path = this.getPath(componentName);
        const cache = this.getStorage();
        const result = cache.has(path);
        if (result.has) {
            return result.value;
        }

        return null;
    }

    // 整理SortSettings中的信息为WebApi接收的结构
    public static getSortSettingsForBackend(componentName: string): SortInfo[] {
        const ss = this.getSortSettings(componentName);
        if (!ss) {
            return null;
        }
        return ss.map(
            si => new SortInfo({ field: si.field, direction: si.direction })
        );
    }

    // 设置VisibleColumns的值，若以前存在则更新
    public static setVisibleColumns(
        componentName: string,
        visibleColumns: string[]
    ): void {
        let cs: IColumnSettings = this.getColumnSettings(componentName);
        if (!cs) {
            cs = { VisibleColumns: visibleColumns };
        } else {
            cs.VisibleColumns = visibleColumns;
        }
        this.setColumnSettings(componentName, cs);
    }

    // 设置SortSettings的值，若以前存在则更新
    public static setSortSettings(
        componentName: string,
        sortSettings: ISortInfo[]
    ): void {
        let cs: IColumnSettings = this.getColumnSettings(componentName);
        if (!cs) {
            cs = { SortSettings: sortSettings };
        } else {
            cs.SortSettings = sortSettings;
        }
        this.setColumnSettings(componentName, cs);
    }

    private static storageKey: string = "ColumnSettings";
    private static storage: any = null;

    private static getPath(componentName: string): string {
        return `${componentName}Settings`;
    }

    // 设置WebStorage的类型、有效时间
    private static getStorage(): any {
        if (this.storage === null) {
            this.storage = nattyStorage(
                Util.getCacheOption(
                    `${tenantInfo.getAbbreviationCode()}-${this.storageKey}`,
                    -1
                ) // -1 means that the data will not expire
            );
        }
        return this.storage;
    }

    // 把相应信息存储在对应的WebStorage中
    private static setColumnSettings(
        componentName: string,
        columnSettings: IColumnSettings
    ): void {
        const path = this.getPath(componentName);
        const cache = this.getStorage();
        cache.set(path, columnSettings);
    }
}

export default ColumnSettings;
