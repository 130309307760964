import {
    StockInAndOutRecordType,
    StoneArtifactType
} from "../app/WebAPIClients";

class StockInAndOutRecordUtil {
    public getStockInAndOutRecordTypeText(
        type: StockInAndOutRecordType
    ): string {
        let text = "";
        if (!type) {
            return text;
        }
        switch (type) {
            case StockInAndOutRecordType.StockingIn:
                text = "入库";
                break;
            case StockInAndOutRecordType.StockingOut:
                text = "出库";
                break;
        }
        return text;
    }

    public getStockInAndOutRecordUnit(type: StoneArtifactType): string {
        let unit = "";
        if (!type) {
            return unit;
        }
        switch (type) {
            case StoneArtifactType.Block:
                unit = "立方";
                break;
            case StoneArtifactType.Slab:
            case StoneArtifactType.StoneBundle:
            case StoneArtifactType.Tile:
                unit = "平方";
                break;
        }
        return unit;
    }
}

export default new StockInAndOutRecordUtil();
