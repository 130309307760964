import { ISalesOrderDetailDTO, StoneArtifactType } from "../app/WebAPIClients";

class StoneArtifactUtil {
    public getBlockItems<T extends ISalesOrderDetailDTO>(items: T[]): T[] {
        return items.filter(d => d.type === StoneArtifactType.Block);
    }

    public getBundleItems<T extends ISalesOrderDetailDTO>(items: T[]): T[] {
        return items.filter(d => d.type === StoneArtifactType.StoneBundle);
    }

    public getTilePackingCaseItems<T extends ISalesOrderDetailDTO>(
        items: T[]
    ): T[] {
        return items.filter(d => d.type === StoneArtifactType.Tile);
    }
}

export default new StoneArtifactUtil();
