import nattyStorage from "natty-storage";
import ClientFactory from "../app/WebAPIClientFactory";
import {
  CustomerForListDTO,
  FactoryDTO,
  IMachineryDTO,
  IQuarryDTO,
  PagedResultOfListOfQuarryDTO,
  PagedResultOfListOfStockingAreaDTO,
  PagedResultOfListOfStoneCategoryDTO,
  PagedResultOfListOfStoneGradeDTO,
  StockingAreaDTO,
  StoneCategoryDTO,
  StoneGradeDTO,
  WarehouseDTO,
  WarehouseType,
} from "../app/WebAPIClients";
import tenantInfo from "./TenantInfo";
import Util from "./Util";

class Helper {
  public static getTenantKey(suffix: string): string {
    return `${tenantInfo.getAbbreviationCode()}-${suffix}`;
  }
}

class WorkOrderType {
  private data: IOption[];

  constructor() {
    this.data = [
      {
        value: 10,
        text: "荒料",
      },
      {
        value: 20,
        text: "光板",
      },
      {
        value: 30,
        text: "毛板",
      },
      {
        value: 40,
        text: "工程板",
      },
    ];
  }

  public getOrderType(value: number) {
    return this.data.find((wot) => wot.value === value);
  }
}

class SalesOrderStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 5,
        text: "草稿",
      },
      {
        value: 10,
        text: "审批中",
      },
      {
        value: 20,
        text: "已批准",
      },
      {
        value: 30,
        text: "未通过审批",
      },
      {
        value: 40,
        text: "已完成",
      },
      {
        value: 50,
        text: "已取消",
      },
    ];
  }

  public getStatus(value: number) {
    return this.data.find((sos) => sos.value === value);
  }
}

class ShippingOrderStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "新建",
      },
      {
        value: 20,
        text: "等待财务核查",
      },
      {
        value: 30,
        text: "等待出厂核查",
      },
      {
        value: 40,
        text: "已出厂",
      },
      {
        value: 50,
        text: "已取消",
      },
    ];
  }

  public getStatus(value) {
    return this.data.find((sos) => sos.value === value);
  }
}

class SlabType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "毛板",
      },
      {
        value: 20,
        text: "光板",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}

class SlabBundleStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "在生产",
      },
      {
        value: 20,
        text: "在库存",
      },
      {
        value: 30,
        text: "被领出",
      },
      {
        value: 40,
        text: "已售出",
      },
      {
        value: 50,
        text: "销售冻结",
      },
      {
        value: 51,
        text: "领料冻结",
      },
      {
        value: 52,
        text: "退料冻结",
      },
      {
        value: 60,
        text: "已报废",
      },
    ];
  }

  public getStatus(value) {
    return this.data.find((sbs) => sbs.value === value);
  }
}

class BlockStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 5,
        text: "已收料",
      },
      {
        value: 10,
        text: "在库存",
      },
      {
        value: 20,
        text: "生产中",
      },
      {
        value: 30,
        text: "生产完成",
      },
      {
        value: 40,
        text: "已售出",
      },
      {
        value: 50,
        text: "冻结",
      },
      {
        value: 100,
        text: "已报废",
      },
    ];
  }

  public getStatus(value) {
    return this.data.find((b) => b.value === value);
  }
}

class SalesOrderType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "库存荒料",
      },
      // {
      //     value: 20,
      //     text: '无库存荒料'
      // },
      {
        value: 30,
        text: "库存大板",
      },
      {
        value: 40,
        text: "无库存大板",
      },
      // {
      //     value: 50,
      //     text: '无库存毛板'
      // }
      {
        value: 60,
        text: "工程板",
      },
      // {
      //     value: 70,
      //     text: '外来料纯加工'
      // },
      {
        value: 80,
        text: "销售荒料代加工",
      },
    ];
  }

  public getOrderType(value) {
    return this.data.find((sot) => sot.value === value);
  }
}

class ManufacturingState {
  public data: IOption[];
  public slabMSData: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "等待领料",
      },
      {
        value: 15,
        text: "领料审批",
      },
      {
        value: 20,
        text: "等待修边",
      },
      {
        value: 25,
        text: "等待修边质检",
      },
      {
        value: 30,
        text: "等待大切",
      },
      {
        value: 35,
        text: "等待大切分扎",
      },
      {
        value: 40,
        text: "等待决定工序",
      },
      {
        value: 43,
        text: "等待补胶",
      },
      {
        value: 45,
        text: "等待补胶质检",
      },
      {
        value: 50,
        text: "等待磨抛质检",
      },
      {
        value: 55,
        text: "等待磨抛确认",
      },
      // {
      //     value: 70,
      //     text: "工程板生产完成"
      // },
      {
        value: 80,
        text: "已取消",
      },
      {
        value: 90,
        text: "已完成",
      },
    ];
    this.slabMSData = [
      {
        value: 40,
        text: "等待决定工序",
      },
      {
        value: 43,
        text: "等待补胶",
      },
      {
        value: 45,
        text: "等待补胶质检",
      },
      {
        value: 50,
        text: "等待磨抛质检",
      },
      {
        value: 55,
        text: "等待磨抛确认",
      },
      {
        value: 90,
        text: "已完成",
      },
    ];
  }

  public getState(value) {
    return this.data.find((ms) => ms.value === value);
  }
}

class ApprovalStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "审批中",
      },
      {
        value: 20,
        text: "审批不通过",
      },
      {
        value: 30,
        text: "已完成",
      },
      {
        value: 40,
        text: "已取消",
      },
    ];
  }

  public getStatus(value) {
    return this.data.find((ms) => ms.value === value);
  }
}

class TilePackingCaseStatusOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "规划完成",
      },
      {
        value: 20,
        text: "生产完成",
      },
      {
        value: 30,
        text: "在库存",
      },
      {
        value: 40,
        text: "冻结",
      },
      {
        value: 50,
        text: "已售出",
      },
      {
        value: 60,
        text: "已报废",
      },
    ];
  }

  public getStatus(value) {
    return this.data.find((ms) => ms.value === value);
  }
}

class Priority {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 1,
        text: "正常",
      },
      {
        value: 2,
        text: "加急",
      },
      {
        value: 3,
        text: "特急",
      },
    ];
  }

  public getPriority(value) {
    return this.data.find((st) => st.value === value);
  }
}

class ManufacturingMethod {
  public combinationData: IOption[];
  public processingData: IOption[];
  private data: IOption[];
  constructor() {
    this.data = [
      {
        value: 0,
        text: "无补无磨",
      },
      {
        value: 1,
        text: "正常加工",
      },
      {
        value: 2,
        text: "正反加工",
      },
      {
        value: 4,
        text: "补胶",
      },
      {
        value: 8,
        text: "磨抛",
      },
    ];

    this.combinationData = [
      {
        value: 0,
        text: "无补无磨",
      },
      {
        value: 5,
        text: "正常补",
      },
      {
        value: 6,
        text: "正反补",
      },
      {
        value: 9,
        text: "正常磨",
      },
      {
        value: 10,
        text: "正反磨",
      },
      {
        value: 13,
        text: "正常补磨",
      },
      {
        value: 14,
        text: "正反补磨",
      },
    ];

    this.processingData = [
      {
        value: 1,
        text: "正常加工",
      },
      {
        value: 2,
        text: "正反加工",
      },
    ];
  }

  public getManufacturingMethod(value) {
    const data = [...this.data, ...this.combinationData];
    return data.find((st) => st.value === value);
  }
}

class ProcessingType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "大切工艺",
      },
      {
        value: 20,
        text: "补胶工艺",
      },
      {
        value: 30,
        text: "磨抛工艺",
      },
      {
        value: 40,
        text: "工程板工艺",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}

class PolishingType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "粗磨 + 抛光",
      },
      {
        value: 20,
        text: "磨光",
      },
      {
        value: 30,
        text: "亚光",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}
class PaymentOrderStatusOptions {
  public data: IOption[];
  public dataWithApproval: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "新建",
      },
      {
        value: 40,
        text: "完成结算",
      },
      {
        value: 100,
        text: "已取消",
      },
    ];
    this.dataWithApproval = [
      {
        value: 10,
        text: "审批中",
      },
      {
        value: 20,
        text: "等待完成结算",
      },
      {
        value: 30,
        text: "审批不通过",
      },
      {
        value: 40,
        text: "完成结算",
      },
      {
        value: 100,
        text: "已取消",
      },
    ];
  }

  public getStatus(value: number) {
    return this.data.find((sos) => sos.value === value);
  }

  public getStatusWithApproval(value: number) {
    return this.dataWithApproval.find((sos) => sos.value === value);
  }
}

interface ISlabExportOptionsCacheData {
  slabSheetType: SlabSheetType;
  exportGrade: boolean;
  exportNotes: boolean;
}

class SlabExportOption {
  public slabSheetTypeOptions: IOption[];
  public booleanOptions: IOption[];
  private exportTypeCacheKey: string = Helper.getTenantKey("slabExport");
  private storage: any = null;
  constructor() {
    this.slabSheetTypeOptions = [
      {
        value: 10,
        text: "标准",
      },
      {
        value: 20,
        text: "简化",
      },
    ];
    this.booleanOptions = [
      {
        value: true,
        text: "是",
      },
      {
        value: false,
        text: "否",
      },
    ];
  }

  public getSavedExportOptionValue(optionName: SlabExportOptionName): string {
    this.ensureExportOptionStorage();
    const cache = this.getStorage();
    const option = cache.get(this.exportTypeCacheKey) as SlabExportOption;
    return option[optionName].toString();
  }

  public saveExportOption(
    optionName: keyof ISlabExportOptionsCacheData,
    optionValue: SlabSheetType | boolean,
  ) {
    this.ensureExportOptionStorage();
    const cache = this.getStorage();
    const result = cache.has(this.exportTypeCacheKey);
    let value: ISlabExportOptionsCacheData = result.value;
    value = { ...value, [optionName]: optionValue };
    cache.set(this.exportTypeCacheKey, value);
  }

  private getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getCacheOption(Helper.getTenantKey("slabExportCache"), 10),
      );
    }
    return this.storage;
  }

  private ensureExportOptionStorage() {
    const cache = this.getStorage();
    let exportOptions: ISlabExportOptionsCacheData = null;

    const result = cache.has(this.exportTypeCacheKey);
    if (result.has) {
      exportOptions = result.value;
    } else {
      exportOptions = {
        slabSheetType: SlabSheetType.Standard,
        exportGrade: false,
        exportNotes: true,
      };
      cache.set(this.exportTypeCacheKey, exportOptions);
    }
    return exportOptions;
  }
}

class Customers {
  private customerCacheKey: string;
  private storage: any;
  constructor() {
    this.customerCacheKey = Helper.getTenantKey("customers");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getCacheOption(Helper.getTenantKey("customerCache"), 10),
      );
    }
    return this.storage;
  }

  public async getCustomer(id: number): Promise<CustomerForListDTO> {
    const customerList = await this.getCustomers();
    return customerList.find((c) => c.id === id);
  }

  public async ensureCustomers() {
    const cache = this.getStorage();
    const result = cache.has(this.customerCacheKey);
    if (!result.has) {
      let customerList: CustomerForListDTO[] = [];
      let noMoreData = false;
      let pageNo: number = 1;
      const client = ClientFactory.getCustomerClient();
      // 若客户数量超出1000，则多次调用，获取全部客户列表 修复bug 3164 by hxy 20210907
      while (!noMoreData) {
        const response = await client.getAll(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          pageNo,
          1000,
          true,
          true,
        );

        noMoreData = response.pagingInfo.noMoreData;
        pageNo += 1;
        customerList = customerList.concat(response.result);
      }

      cache.set(this.customerCacheKey, customerList);
    }
  }

  public async getCustomers(): Promise<CustomerForListDTO[]> {
    await this.ensureCustomers();
    const cache = this.getStorage();
    return cache.get(this.customerCacheKey);
  }
}

class StoneCategories {
  private categoryCacheKey: string;
  private storage: any;
  constructor() {
    this.categoryCacheKey = Helper.getTenantKey("categories");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getCacheOption(Helper.getTenantKey("categoryCache"), 10),
      );
    }
    return this.storage;
  }

  public async getCategory(id: number): Promise<StoneCategoryDTO> {
    const cateList = await this.getCategories();
    return cateList.find((c) => c.id === id);
  }

  public async ensureCategories(): Promise<PagedResultOfListOfStoneCategoryDTO> {
    const cache = this.getStorage();
    let cateList: PagedResultOfListOfStoneCategoryDTO = null;

    const result = cache.has(this.categoryCacheKey);
    if (result.has) {
      cateList = result.value;
    } else {
      const client = ClientFactory.getStoneCategoryClient();
      cateList = await client.getAll(
        undefined,
        1,
        1000,
        true,
        true,
        undefined,
        undefined,
      );
      cache.set(this.categoryCacheKey, cateList.result);
    }
    return cateList;
  }

  public async getCategories(): Promise<StoneCategoryDTO[]> {
    await this.ensureCategories();
    const cache = this.getStorage();
    return cache.get(this.categoryCacheKey);
  }
}

class StoneGrades {
  private gradeCacheKey: string;
  private storage: any;
  constructor() {
    this.gradeCacheKey = Helper.getTenantKey("grades");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getCacheOption(Helper.getTenantKey("gradeCache"), 10),
      );
    }
    return this.storage;
  }

  public async getGrade(id: number): Promise<StoneGradeDTO> {
    const gradeList = await this.getGrades();
    return gradeList.find((c) => c.id === id);
  }

  public async ensureGrades(): Promise<PagedResultOfListOfStoneGradeDTO> {
    const cache = this.getStorage();
    let gList: PagedResultOfListOfStoneGradeDTO = null;

    const result = cache.has(this.gradeCacheKey);
    if (result.has) {
      gList = result.value;
    } else {
      const client = ClientFactory.getStoneGradeClient();

      gList = await client.getAll(undefined, 1, 1000, true, true);

      cache.set(this.gradeCacheKey, gList.result);
    }
    return gList;
  }

  public async getGrades(): Promise<StoneGradeDTO[]> {
    await this.ensureGrades();
    const cache = this.getStorage();
    return cache.get(this.gradeCacheKey);
  }
}

class BlockStockingAreas {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("blockStockingAreaStorage");
    this.cacheKey = Helper.getTenantKey("blockStockingAreaCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getStockingArea(id: number): Promise<StockingAreaDTO> {
    const bsaList = await this.getStockingAreas();
    return bsaList.find((c) => c.id === id);
  }

  public async ensureBSAs(): Promise<PagedResultOfListOfStockingAreaDTO> {
    const cache = this.getStorage();
    let list: PagedResultOfListOfStockingAreaDTO = null;

    const result = cache.has(this.cacheKey);
    if (result.has) {
      list = result.value;
    } else {
      const client = ClientFactory.getStockingAreaClient();
      list = await client.getAll(
        undefined,
        undefined,
        undefined,
        [WarehouseType.Block],
        1000,
        1,
        true,
        true,
      );

      cache.set(this.cacheKey, list.result);
    }
    return list;
  }

  public async getStockingAreas(): Promise<StockingAreaDTO[]> {
    await this.ensureBSAs();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class ProductStockingAreas {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("productStockingAreaStorage");
    this.cacheKey = Helper.getTenantKey("productStockingAreaCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getStockingArea(id: number): Promise<StockingAreaDTO> {
    const gradeList = await this.getStockingAreas();
    return gradeList.find((c) => c.id === id);
  }

  public async ensurePSAs(): Promise<PagedResultOfListOfStockingAreaDTO> {
    const cache = this.getStorage();
    let list = null;

    const result = cache.has(this.cacheKey);
    if (result.has) {
      list = result.value;
    } else {
      const client = ClientFactory.getStockingAreaClient();
      list = await client.getAll(
        undefined,
        undefined,
        undefined,
        [WarehouseType.Product],
        1000,
        1,
        true,
        true,
      );
      cache.set(this.cacheKey, list.result);
    }
    return list;
  }

  public async getStockingAreas(): Promise<StockingAreaDTO[]> {
    await this.ensurePSAs();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class Quarry {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("quarryStorage");
    this.cacheKey = Helper.getTenantKey("quarryCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getQuarry(id: number): Promise<IQuarryDTO> {
    const quarryList = await this.getQuarries();
    return quarryList.find((c) => c.id === id);
  }

  public async ensureQuarries(): Promise<PagedResultOfListOfQuarryDTO> {
    const cache = this.getStorage();
    let list: PagedResultOfListOfQuarryDTO = null;

    const result = cache.has(this.cacheKey);
    if (result.has) {
      list = result.value;
    } else {
      const client = ClientFactory.getQuarryClient();

      list = await client.getAll(undefined, true, true, 1000, 1);

      cache.set(this.cacheKey, list.result);
    }
    return list;
  }

  public async getQuarries(): Promise<IQuarryDTO[]> {
    await this.ensureQuarries();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class Factories {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("factoryStorage");
    this.cacheKey = Helper.getTenantKey("factoryCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getFactory(id: number): Promise<FactoryDTO> {
    const factoryList = await this.getFactories();
    return factoryList.find((f) => f.id === id);
  }

  public async ensureFactories(): Promise<FactoryDTO[]> {
    const cache = this.getStorage();
    let list: FactoryDTO[] = null;

    const result = cache.has(this.cacheKey);
    if (result.has) {
      list = result.value;
    } else {
      const client = ClientFactory.getFactoryClient();
      list = await client.getAll();
      cache.set(this.cacheKey, list);
    }
    return list;
  }

  public async getFactories(): Promise<FactoryDTO[]> {
    await this.ensureFactories();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class Warehouses {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;

  constructor() {
    this.storageKey = Helper.getTenantKey("warehousesStorage");
    this.cacheKey = Helper.getTenantKey("warehousesCache");
    this.storage = null;
  }

  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getWarehouse(id: number): Promise<WarehouseDTO> {
    const warehouseList = await this.getWarehouses();
    return warehouseList.find((c) => c.id === id);
  }

  public async getWarehouses(): Promise<WarehouseDTO[]> {
    await this.ensureWarehouses();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }

  public async ensureWarehouses(): Promise<WarehouseDTO[]> {
    const cache = this.getStorage();
    let list = null;

    const result = cache.has(this.cacheKey);
    if (result.has) {
      list = result.value;
    } else {
      const client = ClientFactory.getWarehouseClient();
      list = await client.getAll();
      cache.set(this.cacheKey, list);
    }
    return list;
  }
}

class Machineries {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("machineryStorage");
    this.cacheKey = Helper.getTenantKey("machineryCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getMachinery(id: number): Promise<IMachineryDTO> {
    const machineries = await this.getMachineries();
    return machineries.find((m) => m.id === id);
  }

  public async ensureMachineries() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getMachineryClient();
      const response = await client.getAll(
        undefined,
        undefined,
        undefined,
        undefined,
        1,
        1000,
        true,
        true,
      );
      cache.set(this.cacheKey, response.result);
    }
  }

  public async getMachineries(): Promise<IMachineryDTO[]> {
    await this.ensureMachineries();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class StockInAndOutRecordTypeOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "入库",
      },
      {
        value: 20,
        text: "出库",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}

class StoneArtifactTypeOptions {
  public data: IOption[];
  public productTypeData: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "荒料",
      },
      {
        value: 20,
        text: "扎",
      },
      {
        value: 25,
        text: "大板",
      },
      {
        value: 30,
        text: "工程板",
      },
    ];
    this.productTypeData = [
      {
        value: 25,
        text: "散板",
      },
      {
        value: 20,
        text: "扎",
      },
      {
        value: 30,
        text: "工程板",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }

  public getProductType(value) {
    return this.productTypeData.find((st) => st.value === value);
  }
}

class ProcessConst {
  public trimmingProcessName: string = "对剖";
  public trimmingProcessUnitName: string = "刀";
  public trimmingConventionalCutsName: string = "平常刀";
  public trimmingDilatedCutsName: string = "加宽";
  public trimmingThickenCutsName: string = "加厚";
  public trimmingCreviceHeadCutsName: string = "隙头";
  public trimmingCreviceBrimCutsName: string = "隙边";
}

export enum SlabExportOptionName {
  SlabSheetType = "slabSheetType",
  ExportGrade = "exportGrade",
  ExportNotes = "exportNotes",
}

export enum SlabSheetType {
  Standard = 10,
  Simplified = 20,
}

export const formItemLayoutForSearch = {
  labelCol: {
    xs: 12,
    sm: 10,
    md: 8,
    lg: 8,
    xl: 6,
    xxl: 6,
  },
  wrapperCol: {
    xs: 12,
    sm: 10,
    md: 16,
    lg: 16,
    xl: 18,
    xxl: 18,
  },
};

export const formItemLayoutForCheckboxGroup = {
  labelCol: {
    xs: 10,
    sm: 10,
    md: 4,
    lg: 4,
    xl: 2,
    xxl: 2,
  },
  wrapperCol: {
    xs: 14,
    sm: 14,
    md: 20,
    lg: 20,
    xl: 22,
    xxl: 22,
  },
};

export const formItemLayoutForCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 8,
  xxl: 8,
};

export const colLayoutForInput = {
  xs: 24,
  sm: 24,
  md: 4,
  lg: 8,
  xl: 8,
  xxl: 8,
};

export const colLayoutForButton = {
  xs: 24,
  sm: 24,
  md: 20,
  lg: 16,
  xl: 16,
  xxl: 16,
};

class Consts {
  public static WorkOrderType = new WorkOrderType();
  public static SalesOrderType = new SalesOrderType();
  public static SlabType = new SlabType();
  public static SlabBundleStatus = new SlabBundleStatus();
  public static ManufacturingState = new ManufacturingState();
  public static SalesOrderStatus = new SalesOrderStatus();
  public static StoneGrades = new StoneGrades();
  public static StoneCategories = new StoneCategories();
  public static ProductStockingAreas = new ProductStockingAreas();
  public static BlockStockingAreas = new BlockStockingAreas();
  public static ShippingOrderStatus = new ShippingOrderStatus();
  public static BlockStatus = new BlockStatus();
  public static ApprovalStatus = new ApprovalStatus();
  public static TilePackingCaseStatusOptions =
    new TilePackingCaseStatusOptions();
  public static Priority = new Priority();
  public static ProcessingType = new ProcessingType();
  public static ManufacturingMethod = new ManufacturingMethod();
  public static Quarry = new Quarry();
  public static PolishingType = new PolishingType();
  public static Customers = new Customers();
  public static SlabExportOption = new SlabExportOption();
  public static PaymentOrderStatusOptions = new PaymentOrderStatusOptions();
  public static Factories = new Factories();
  public static Warehouses = new Warehouses();
  public static StockInAndOutRecordTypeOptions =
    new StockInAndOutRecordTypeOptions();
  public static StoneArtifactTypeOptions = new StoneArtifactTypeOptions();
  public static Machineries = new Machineries();
  public static ProcessConst = new ProcessConst();
}

export default Consts;
