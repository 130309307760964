import React from "react";

import { DownOutlined } from "@ant-design/icons";

import { Button, Dropdown, Form, Menu, Radio } from "antd";
import Consts, { SlabExportOptionName } from "../Consts";
import PermCtrl from "../PermCtrl";
import Perm from "../../app/Perm";
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { SlabExportOption } = Consts;

class ExportSelector extends React.Component<any, any> {
    public render() {
        const t = this;
        const formItemLayout = {
            labelCol: { span: 9 },
            wrapperCol: { span: 15 }
        };

        const showBundleGrades = PermCtrl.isAuthorized(Perm.SB_G_R);

        const slabSheetType = t.getExportOptionValue(
            SlabExportOptionName.SlabSheetType
        );
        const exportGrade = t.getExportOptionValue(
            SlabExportOptionName.ExportGrade
        );
        const exportNotes = t.getExportOptionValue(
            SlabExportOptionName.ExportNotes
        );

        const menu = (
            <Menu>
                <Menu.Item>
                    <FormItem {...formItemLayout} label="码单格式">
                        <RadioGroup
                            onChange={t.handleSlabSheetTypeChanged}
                            defaultValue={slabSheetType}
                        >
                            <Radio value={"10"}>标准</Radio>
                            <Radio value={"20"}>简化</Radio>
                        </RadioGroup>
                    </FormItem>
                </Menu.Item>
                {showBundleGrades ? (
                    <Menu.Item>
                        <FormItem {...formItemLayout} label="导出等级">
                            <RadioGroup
                                onChange={t.handleExportGradeChanged}
                                defaultValue={exportGrade}
                            >
                                <Radio value={"true"}>是</Radio>
                                <Radio
                                    value={"false"}
                                    style={{ marginLeft: "14px" }}
                                >
                                    否
                                </Radio>
                            </RadioGroup>
                        </FormItem>
                    </Menu.Item>
                ) : null}
                <Menu.Item>
                    <FormItem {...formItemLayout} label="导出备注">
                        <RadioGroup
                            onChange={t.handleExportNotesChanged}
                            defaultValue={exportNotes}
                        >
                            <Radio value={"true"}>是</Radio>
                            <Radio
                                value={"false"}
                                style={{ marginLeft: "14px" }}
                            >
                                否
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown overlay={menu}>
                <Button>
                    导出码单选项 <DownOutlined />
                </Button>
            </Dropdown>
        );
    }

    private getExportOptionValue(optionName: SlabExportOptionName) {
        return SlabExportOption.getSavedExportOptionValue(optionName);
    }

    private handleExportGradeChanged = e => {
        // 由于slabExportCache中exportGrade值类型为boolean,所以在此将e.target.value转换为boolean
        const value: boolean = e.target.value === "true" ? true : false;
        SlabExportOption.saveExportOption(
            SlabExportOptionName.ExportGrade,
            value
        );
    };

    private handleExportNotesChanged = e => {
        // 由于slabExportCache中exportNotes值类型为boolean,所以在此将e.target.value转换为boolean
        const value: boolean = e.target.value === "true" ? true : false;
        SlabExportOption.saveExportOption(
            SlabExportOptionName.ExportNotes,
            value
        );
    };

    private handleSlabSheetTypeChanged = e => {
        // 由于slabExportCache中slabSheetType值类型为number,所以在此将e.target.value转换为number
        SlabExportOption.saveExportOption(
            SlabExportOptionName.SlabSheetType,
            parseInt(e.target.value)
        );
    };
}

export default ExportSelector;
