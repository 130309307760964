import * as dd from "dingtalk-jsapi";
// tslint:disable-next-line: no-submodule-imports
import { ENV_ENUM } from "dingtalk-jsapi/lib/sdk";
import userInfo from "./UserInfo";

class DingtalkClientSVC {
    public static isJSAPIAvailable(): boolean {
        return !(
            typeof dd === "undefined" ||
            dd === null ||
            typeof dd.env.platform === "undefined" ||
            dd.env.platform === ENV_ENUM.notInDingTalk
        );
    }

    // 获取钉钉临时授权码，用于在WebAPI的Get方法中
    public static async getDDAuthCodeForMessageAsync(): Promise<string> {
        if (!this.isJSAPIAvailable()) {
            return null;
        } // 如果客户端钉钉对象不可用（可能是浏览器)，则直接返回成功
        else {
            const ddConfig = userInfo.getDDConfig();
            return this.getTempAuthCodeAsync(ddConfig.corpId, ddConfig.agentId);
        }
    }

    // 将授权码添加到需要被发送到服务端的数据中，临时授权码保存在authCode属性中
    public static async addAuthCodeForMessageAsync(postData): Promise<void> {
        if (!this.isJSAPIAvailable()) {
            return;
        } // 如果客户端钉钉对象不可用（可能是浏览器)，则直接返回成功
        else {
            const ddConfig = userInfo.getDDConfig();
            const authCode = await this.getTempAuthCodeAsync(
                ddConfig.corpId,
                ddConfig.agentId
            );

            if (!(typeof authCode === "undefined" || authCode === null)) {
                postData.authCode = authCode;
            }
        }
    }

    // 通过corpId和agentId从DingTalkPC.runtime中拿到临时授权码，用于发送消息
    public static async getTempAuthCodeAsync(corpId, agentId): Promise<string> {
        const result = await dd.runtime.permission.requestOperateAuthCode({
            corpId,
            agentId
        });
        return result.code;
    }
}

export default DingtalkClientSVC;
