import { FileExcelOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React from "react";
import contentDispositionParse from "content-disposition-parser";
import { FileResponse } from "../app/WebAPIClients";
import ErrorHandler from "./ErrorHandler";
import { ButtonType } from "antd/lib/button";

interface IFileDownloadButtonProps {
    text: string;
    onDownload: () => Promise<FileResponse>;
    type?: ButtonType;
    icon?: React.ReactNode;
}

export const FileDownloadButton = (props: IFileDownloadButtonProps) => {
    const { text, onDownload } = props;

    const onClick: React.MouseEventHandler<HTMLElement> = async () => {
        message.success("正在下载文件...");

        try {
            const response = await onDownload();
            const type = response.data.type;
            const parser = contentDispositionParse(
                response.headers["content-disposition"]
            );
            const blob = new Blob([response.data], { type });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = parser.filename;
            a.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            const err = { message: JSON.stringify(error), code: -1 };
            ErrorHandler.handleErr("下载文件出错", err);
        }
    };

    let icon = props.icon;
    if (!icon) {
        icon = <FileExcelOutlined />;
    }

    let type = props.type;
    if (!type) {
        type = "link";
    }

    return (
        <Button type={type} icon={icon} onClick={onClick}>
            {text}
        </Button>
    );
};
