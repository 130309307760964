import nattyStorage from "natty-storage";
import Util from "./Util";

class TenantInfo {
  public static setTenantInfo(tenantInfo: ITenantInfo): void {
    if (!tenantInfo) {
      return;
    }
    const cache = this.getStorage();
    cache.set(this.cacheKey, tenantInfo);
  }

  public static getTenantInfo(): ITenantInfo {
    const cache = this.getStorage();
    const tenantInfo: ITenantInfo = cache.get(this.cacheKey);
    if (!tenantInfo) {
      return null;
    }
    return tenantInfo;
  }

  public static getAbbreviationCode(): string {
    const tenantInfo = this.getTenantInfo();
    return tenantInfo == null ? null : tenantInfo.abbreviationCode;
  }

  public static getName(): string {
    const tenantInfo = this.getTenantInfo();
    return tenantInfo == null ? null : tenantInfo.name;
  }

  public static getLogoUrl(): string {
    const tenantInfo = this.getTenantInfo();
    return tenantInfo == null ? null : tenantInfo.logoUrl;
  }

  public static getBannerUrl(): string {
    const tenantInfo = this.getTenantInfo();
    return tenantInfo == null ? null : tenantInfo.bannerUrl;
  }

  private static storage: any = null;
  private static storageKey: string = "tenantInfoStorage";
  private static cacheKey: string = "tenantInfo";
  private static cacheDurationInMin: number = 10;

  private static getStorage(): any {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, this.cacheDurationInMin),
      );
    }
    return this.storage;
  }
}
export default TenantInfo;
