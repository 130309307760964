import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../app/WebAPIClientFactory";
import { SystemInfoDTO } from "../../app/WebAPIClients";
import Base from "../../stores/base";

class Store extends Base {
    @observable
    public backendSysInfo: SystemInfoDTO = null;

    @action
    public getSystemInfo = async () => {
        const t = this;
        const sysClient = ClientFactory.getSystemClient();
        const callAPI = async () => {
            const result = await sysClient.getSystemInfo();
            runInAction(() => {
                this.backendSysInfo = result;
            });
        };

        await t.callAPIFunForGet(callAPI);
    };
}

export default new Store();
