import { Button, Col, Form, Row } from "antd";
import React from "react";

const FormItem = Form.Item;
export interface ISearchButtonGroupProps {
    buttonDisabled?: boolean;
    onClearClick?: () => void;
    extraInfo?: JSX.Element;
}

class SearchButtonGroup extends React.Component<ISearchButtonGroupProps, {}> {
    public static defaultProps: ISearchButtonGroupProps;
    constructor(props: ISearchButtonGroupProps) {
        super(props);
    }

    public render(): JSX.Element {
        const t = this;
        const { onClearClick, buttonDisabled, extraInfo } = t.props;
        const formItemLayout = {
            labelCol: {
                span: 0
            },
            wrapperCol: {
                span: 24
            }
        };
        return (
            <Row gutter={24}>
                <Col span={24} style={{ textAlign: "right" }}>
                    <FormItem
                        style={{
                            marginBottom: "0px"
                        }}
                        {...formItemLayout}
                    >
                        <Button
                            disabled={buttonDisabled}
                            type="primary"
                            htmlType="submit"
                        >
                            搜索
                        </Button>
                        <Button
                            disabled={buttonDisabled}
                            danger={true}
                            onClick={onClearClick}
                        >
                            重置
                        </Button>
                        {extraInfo}
                    </FormItem>
                </Col>
            </Row>
        );
    }
}

SearchButtonGroup.defaultProps = {
    onClearClick: () => {},
    buttonDisabled: false,
    extraInfo: null
};

export default SearchButtonGroup;
