import React from "react";
import Util from "./Util";

class UnitPriceUtil {
    public showUnitPriceJSX = (unitPrice: number | null | undefined) => {
        return Util.isDefinedAndNotNull(unitPrice) ? (
            <span className="referencePriceColor">¥{unitPrice}</span>
        ) : null;
    };
}

export default new UnitPriceUtil();
