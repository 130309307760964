import { Select } from "antd";
import React from "react";
import Util from "./Util";
const Option = Select.Option;

class StockingAreaUtil {
    // 获取仓库级联数据
    public generateWarehouseCascadData(warehouseList, factories) {
        const options = [];

        warehouseList.forEach(element => {
            const wo = options.find(w => w.value === element.factoryId);
            if (wo) {
                wo.children.push({
                    value: element.id,
                    label: element.name
                });
            } else {
                options.push({
                    value: element.factoryId,
                    label: Util.getItemName(element.factoryId, factories),
                    children: [
                        {
                            value: element.id,
                            label: element.name
                        }
                    ]
                });
            }
        });

        return options;
    }

    // 根据Id获取仓库
    public getWarehouseForUI(
        warehouseId: number,
        warehouseOptions,
        descending: boolean = false
    ) {
        const warehouse = [];
        if (!(warehouseOptions && warehouseId)) {
            return null;
        }

        let factoryOptions = null;
        let warehouseOption = null;
        for (const option of warehouseOptions) {
            warehouseOption = option.children.find(
                o => o.value === warehouseId
            );
            if (warehouseOption) {
                factoryOptions = option;
                break;
            }
        }
        if (!(warehouseOption && factoryOptions)) {
            return null;
        }

        if (descending) {
            warehouse.push(warehouseOption.label, factoryOptions.label);
        } else {
            warehouse.push(factoryOptions.label, warehouseOption.label);
        }

        return warehouse;
    }

    public getStockingAreaOptions(
        unique: boolean,
        warehouseId: number,
        saList,
        warehouseOptions
    ) {
        saList = saList.filter(
            sa => sa.warehouseId === warehouseId || !warehouseId
        );

        return saList.map(p => {
            return (
                <Option key={p.id} value={p.id}>
                    {this.getShowText(unique, p, warehouseOptions)}
                </Option>
            );
        });
    }

    public getShowText(unique: boolean, stockingArea, warehouseOptions) {
        if (unique) {
            return stockingArea.name;
        }

        const warehouse = this.getWarehouseForUI(
            stockingArea.warehouseId,
            warehouseOptions,
            true
        );
        return stockingArea.name + "-" + warehouse.join("-");
    }
}

export default new StockingAreaUtil();
