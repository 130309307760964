import React from "react";
import { FileResponse, ImportingTemplateClient } from "../app/WebAPIClients";
import ClientFactory from "../app/WebAPIClientFactory";
import { FileDownloadButton } from "./FileDownloadButton";

interface ITemplateFileDownloadButtonProps {
    onDownload: (client: ImportingTemplateClient) => Promise<FileResponse>;
}

export const TemplateFileDownloadButton = (
    props: ITemplateFileDownloadButtonProps
) => {
    const { onDownload } = props;

    const onFileDownload = () => {
        const client = ClientFactory.getImportingTemplateClient();
        return onDownload(client);
    };

    return (
        <FileDownloadButton text="下载导入模版" onDownload={onFileDownload} />
    );
};
