let backendHost;
let environment;

const location = window && window.location;
const hostname = location && window.location.hostname;

if (hostname.includes("testing.stoneerp.net")) {
    environment = "testing";
    backendHost = "https://api.testing.stoneerp.net";
} else if (hostname.includes("staging.stoneerp.net")) {
    environment = "staging";
    backendHost = "https://api.staging.stoneerp.net";
} else if (hostname.includes("mobile.demo.stoneerp.net")) {
    environment = "demo";
    backendHost = "https://api.demo.stoneerp.net";
} else if (
    hostname.includes("pc.stoneerp.net") ||
    hostname.includes("mobile.stoneerp.net")
) {
    environment = "production";
    backendHost = "https://api.stoneerp.net";
} else {
    environment = "development";
    backendHost = !location
        ? ""
        : location.protocol + "//" + location.hostname + ":5080";
}

const isDevEnv = environment === "development";

const settings = {
    backendHost: `${backendHost}`,
    webApiUrl: `${backendHost}/api/`,
    webApiUrlForOSSCallback: `${backendHost}/api/`.replace("https", "http"),
    environment,
    isDevEnv
};

export default settings;
