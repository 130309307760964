// tslint:disable-next-line: no-implicit-dependencies
import moment from "moment";
import {
    IAppliedProcessDTO,
    ISlabForListDTO,
    IUserInfoDTO
} from "../app/WebAPIClients";
import ColumnSettings from "./ColumnSettings";
import PermCtl from "./PermCtrl";

class Util {
    public getCacheOption(
        key: string,
        durationInMin: number
    ): INattyStorageCacheOption {
        let option: INattyStorageCacheOption = {
            type: "localStorage", // 缓存方式, 默认为'localStorage'
            key // !!! 唯一必选的参数, 用于内部存储 !!!
        };

        if (durationInMin > 0) {
            option = {
                ...option,
                duration: 1000 * 60 * durationInMin // 缓存的有效期长, 以毫秒数指定
            };
        }

        return option;
    }

    public getSessionCacheOption(
        key: string,
        durationInMin: number
    ): INattyStorageCacheOption {
        return {
            type: "sessionStorage",
            key
        };
    }

    // 用于获取React.Component的props.location.state的属性值
    // 主要用在页面初始化state的时候
    // obj - React.Component对象，在constructor中可以传this
    // name - 属性的名字
    // defaultValue - 如果属性不存在时使用的默认值，如果不传此参数，则默认为null
    public getLocState<T>(
        obj: React.Component<any, any>,
        name: string,
        defaultValue?: T
    ): T {
        if (typeof defaultValue === "undefined") {
            defaultValue = null;
        }

        if (
            !(
                obj &&
                obj.props &&
                obj.props.location &&
                obj.props.location.state
            )
        ) {
            return defaultValue;
        }

        let returnVal = defaultValue;
        const stateObj = obj.props.location.state;
        if (stateObj && typeof stateObj[name] !== "undefined") {
            returnVal = stateObj[name];
        }
        return returnVal;
    }

    public isValidSysUser(ui: IUserInfoDTO): boolean {
        return this.isNotNullAndNotEmptyArray(ui.permissions);
    }

    public getSlabSpec(slab: ISlabForListDTO): string {
        if (!slab) {
            return "";
        }
        const slabSize: ISlabSize = this.getSlabSize(slab);
        return slabSize.length + " x " + slabSize.width;
    }

    public getSlabDeductedSpec(slab: ISlabForListDTO): string {
        if (!slab) {
            return "";
        }
        const slabSize: ISlabSize = this.getSlabSize(slab);
        return slabSize.deductedLength + " x " + slabSize.deductedWidth;
    }

    public calculateAreaForSlab(slab: ISlabForListDTO): number {
        let area = 0;
        let deductedArea = 0;

        if (typeof slab === "undefined" || slab === null) {
            return area;
        }
        const slabSize: ISlabSize = this.getSlabSize(slab);
        const length: number = slabSize.length;
        const width: number = slabSize.width;
        const deductedLength: number = slabSize.deductedLength;
        const deductedWidth: number = slabSize.deductedWidth;

        if (length > 0 && width > 0) {
            area = this.round((length * width) / 1000000, 3);
        }

        if (deductedLength > 0 && deductedWidth > 0) {
            deductedArea = this.round(
                (deductedLength * deductedWidth) / 1000000,
                3
            );
            area = this.round(area - deductedArea, 3);
        }

        return area;
    }

    public round(num: number, precision: number) {
        if (num === null) {
            throw new Error("num cannot be null");
        }
        if (precision === null) {
            throw new Error("precision cannot be null");
        }
        const factor = Math.pow(10, precision);
        return Math.round(num * factor) / factor;
    }

    public getNote(slab): string {
        let note = "";
        if (slab.sawingNote != null) {
            note += slab.sawingNote;
            if (slab.fillingNote != null || slab.polishingNote != null) {
                note += " | ";
            }
        }

        if (slab.fillingNote != null) {
            note += slab.fillingNote;
            if (slab.polishingNote != null) {
                note += " | ";
            }
        }

        if (slab.polishingNote != null) {
            note += slab.polishingNote;
        }

        return note;
    }

    public getAppliedProcessesText(
        appliedProcesses: IAppliedProcessDTO[]
    ): string {
        let text = "";
        if (this.isNotNullAndNotEmptyArray(appliedProcesses)) {
            appliedProcesses
                .slice()
                .sort((ap1, ap2) => ap1.segments - ap2.segments)
                .forEach(ap => {
                    text += ap.name;
                });
        }

        return text;
    }

    // 计算体积
    // length - Number, 长度，单位为毫米
    // width - Number, 宽度，单位为毫米
    // height - Number, 高度，单位为毫米
    // 返回 - string，体积，单位为立方米，保留小数点后三位，第四位四舍五入
    public calculateVolume(
        length: number,
        width: number,
        height: number
    ): number {
        let v = 0;

        if (
            typeof length === "undefined" ||
            typeof width === "undefined" ||
            typeof height === "undefined" ||
            length === null ||
            width === null ||
            height === null
        ) {
            return v;
        }

        if (length > 0 && width > 0 && height > 0) {
            v = this.round((length * width * height) / 1000000000, 3);
        }

        return v;
    }

    public formatDate(date: Date): string {
        if (!this.isDefinedAndNotNull(date)) {
            return null;
        }
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return year + "-" + this.completion(month) + "-" + this.completion(day);
    }

    // 格式化日期：yyyy-MM-dd HH:mm:ss
    public formatDateTime(date: Date, showSecond: boolean = true): string {
        if (!this.isDefinedAndNotNull(date)) {
            return null;
        }

        const dateArray: string[] = [
            this.completion(date.getHours()),
            this.completion(date.getMinutes())
        ];

        if (showSecond) {
            dateArray.push(this.completion(date.getSeconds()));
        }

        return this.formatDate(date) + " " + dateArray.join(":");
    }

    public getTimeConsuming(minutes: number): string {
        if (typeof minutes === "undefined" || minutes === null || minutes < 0) {
            return "";
        }

        const hours = Math.floor(minutes / 60);
        const minute = minutes % 60;
        if (hours === 0) {
            return minute + "分";
        }
        if (minute === 0) {
            return hours + "小时";
        }
        return hours + "小时" + minute + "分";
    }

    public getItemName(itemId: number, itemList: IIdNameItem[]): string {
        let iName = null;
        if (!(itemId && itemList)) {
            return iName;
        }

        const item = itemList.find(i => i.id === itemId);
        if (item) {
            iName = item.name;
        }

        return iName;
    }

    // 转换为百分比显示
    public toPercent(blockOutturnPercentage) {
        return this.round(blockOutturnPercentage * 100, 0) + "%";
    }

    // 获取第一次默认显示的title
    public getDefaultShowingColumns(columnsInfo: ITableColumnInfo[]): string[] {
        return columnsInfo.filter(ci => ci.showByDefault).map(ci => ci.title);
    }

    // 获取Table需要的Columns结构
    public getTableColumns(
        columnsInfo: ITableColumnInfo[],
        componentName: string
    ) {
        const columns = [];
        const columnSettings = ColumnSettings.getColumnSettings(componentName);

        // 如果在WebStorage中取不到VisibleColumn，则使用默认显示的列进行表格显示，否则使用以前设置的值
        const visibleColumns: string[] =
            columnSettings === null ||
            columnSettings.VisibleColumns === null ||
            typeof columnSettings.VisibleColumns === "undefined"
                ? this.getDefaultShowingColumns(columnsInfo)
                : columnSettings.VisibleColumns;

        // 如果在WebStorage中取不到SortInfo，则为null
        const sortInfo: ISortInfo =
            columnSettings === null ||
            columnSettings.SortSettings === null ||
            typeof columnSettings.SortSettings === "undefined" ||
            columnSettings.SortSettings.length <= 0
                ? null
                : columnSettings.SortSettings[0];

        for (const ci of columnsInfo) {
            const visible = visibleColumns.some(vc => vc === ci.title); // 在visibleColumns中判断title是否存在
            if (
                visible &&
                (typeof ci.disabled === "undefined" || !ci.disabled)
            ) {
                if (sortInfo && sortInfo.field === ci.key) {
                    ci.sortOrder =
                        sortInfo.direction === 1 || sortInfo.direction === "ASC"
                            ? "ascend"
                            : "descend";
                } else {
                    ci.sortOrder = false;
                }
                columns.push(ci);
            }
        }

        return columns;
    }

    // 获取WebStorage中对应VisibleColumns的数据
    public getSavedVisibleColumns(componentName: string): string[] {
        return ColumnSettings.getVisibleColumns(componentName);
    }

    // 获取WebStorage中对应VisibleColumns，如果为空则取默认显示的title
    public getVisibleColumns(
        columnsInfo: ITableColumnInfo[],
        componentName: string
    ) {
        let visibleColumns: string[] = this.getSavedVisibleColumns(
            componentName
        );
        if (!visibleColumns) {
            visibleColumns = this.getDefaultShowingColumns(columnsInfo);
        }
        return visibleColumns;
    }

    // 判断是否全部选中
    public judgeCheckAll(
        columnsInfo: ITableColumnInfo[],
        visibleColumns: string[]
    ): boolean {
        return (
            columnsInfo.filter(ci =>
                ci.viewingPermissions && ci.viewingPermissions.length > 0
                    ? PermCtl.isAnyAuthorized(ci.viewingPermissions)
                    : true
            ).length === visibleColumns.length
        );
    }

    // 点击全选后更新WebStorage中对应VisibleColumns的信息
    public checkAllChange(
        columnsInfo: ITableColumnInfo[],
        componentName: string,
        checkAll: boolean
    ) {
        let visibleColumns: string[] = null;

        if (!checkAll) {
            visibleColumns = columnsInfo
                .filter(ci =>
                    ci.viewingPermissions && ci.viewingPermissions.length > 0
                        ? PermCtl.isAnyAuthorized(ci.viewingPermissions)
                        : true
                )
                .map(ci => ci.title);
        } else {
            visibleColumns = columnsInfo
                .filter(
                    ci =>
                        (ci.viewingPermissions &&
                        ci.viewingPermissions.length > 0
                            ? PermCtl.isAnyAuthorized(ci.viewingPermissions)
                            : true) && ci.alwaysShow
                )
                .map(ci => ci.title);
        }

        ColumnSettings.setVisibleColumns(componentName, visibleColumns);
    }

    // 更改排序信息后更新WebStorage中对应SortSettings的信息
    public updateSortSettings(sorter, componentName: string) {
        let sortSettings: ISortInfo[] = null;

        if (
            typeof sorter.columnKey !== "undefined" &&
            typeof sorter.order !== "undefined"
        ) {
            sortSettings = [
                {
                    field: sorter.columnKey,
                    direction: sorter.order === "ascend" ? 1 : 2
                }
            ];
        }
        ColumnSettings.setSortSettings(componentName, sortSettings);
    }

    // 计算面积
    // length - Number, 长度，单位为毫米
    // width - Number, 宽度，单位为毫米
    // 返回 - Number, 面积，单位为平方米，保留小数点后四位，第五位四舍五入
    public calculateArea(length: number, width: number): number {
        let a = 0;

        if (
            typeof length === "undefined" ||
            typeof width === "undefined" ||
            length === null ||
            width === null
        ) {
            return a;
        }

        if (length > 0 && width > 0) {
            a = this.round((length * width) / 1000000, 4);
        }

        return a;
    }

    // 根据传入参数，更新存放展开行key的数组
    // expanded - boolean, 是否展开标志
    // expandedArr - number[], 存放展开行key的数组
    // item - number, 存放展开行key
    // 返回 - number[], 返回更新后的数组
    public updateExpandedArray(
        expandedArr: number[],
        itemId: number,
        expanded: boolean
    ): number[] {
        let newExpandedArr = expandedArr;
        if (!expanded) {
            newExpandedArr = expandedArr.filter(ed => ed !== itemId);
        } else if (!expandedArr.includes(itemId)) {
            newExpandedArr.push(itemId);
        }
        return newExpandedArr;
    }

    public GetUrlParam(url: string, name: string): string {
        try {
            const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            const result = url.split("?")[1].match(reg);
            if (result != null) {
                return result[2];
            }
            return null;
        } catch (e) {
            return null;
        }
    }

    // 获取大板的长宽和扣尺
    public getSlabSize(slab: ISlabForListDTO): ISlabSize {
        let length = 0;
        let width = 0;
        let deductedLength = 0;
        let deductedWidth = 0;
        if (
            slab.lengthAfterPolishing != null &&
            slab.widthAfterPolishing != null
        ) {
            length = slab.lengthAfterPolishing;
            width = slab.widthAfterPolishing;
            deductedLength = slab.deductedLengthAfterPolishing;
            deductedWidth = slab.deductedWidthAfterPolishing;
        } else if (
            slab.lengthAfterFilling != null &&
            slab.widthAfterFilling != null
        ) {
            length = slab.lengthAfterFilling;
            width = slab.widthAfterFilling;
            deductedLength = slab.deductedLengthAfterFilling;
            deductedWidth = slab.deductedWidthAfterFilling;
        } else {
            length = slab.lengthAfterSawing;
            width = slab.widthAfterSawing;
            deductedLength = slab.deductedLengthAfterSawing;
            deductedWidth = slab.deductedWidthAfterSawing;
        }
        return { length, width, deductedLength, deductedWidth };
    }

    public isDefinedAndNotNull(item: any): boolean {
        return typeof item !== "undefined" && item !== null;
    }

    public isNotNullAndNotEmpty(item: any): boolean {
        return this.isDefinedAndNotNull(item) && item !== "";
    }

    public isNotNullAndNotEmptyArray(item: any): boolean {
        return (
            this.isDefinedAndNotNull(item) &&
            this.isDefinedAndNotNull(item.length) &&
            item.length > 0
        );
    }

    public getValidDate(value: string | number | Date): Date {
        if (!this.isDefinedAndNotNull(value)) {
            return undefined;
        }

        const date = new Date(value);
        return date instanceof Date && !isNaN(date.getTime())
            ? date
            : undefined;
    }

    public getMomentArray(
        startTime?: Date | string,
        endTime?: Date | string,
        dateFormat: string = "YYYY-MM-DD"
    ): [moment.Moment, moment.Moment] {
        return startTime && endTime
            ? [moment(startTime, dateFormat), moment(endTime, dateFormat)]
            : undefined;
    }

    public getCascaderValue(value: string[], index: number) {
        if (!value) {
            return null;
        }

        return value && value.length && value.length >= index + 1
            ? value[index]
            : null;
    }
    public parseToInt(value: string): number | null {
        const valid = this.isNumber(value);
        if (!valid) {
            return null;
        }

        return value === null || value === "" ? null : parseInt(value);
    }

    public isNumber(value: string): boolean {
        return !isNaN(parseFloat(value));
    }

    private completion(value: number): string {
        if (value < 10) {
            return "0" + value.toString();
        }
        return value.toString();
    }
}

export default new Util();
